.waitlist-form__button-row {
  input[type='email'] {
    &.waitlist-form__email {
      @include breakpoint($medium-up) {
        height: 27px;
        width: 395px;
      }
      @include breakpoint($small-down) {
        width: 220px;
        height: 30px;
      }
    }
  }
}

.cs-page__content-item {
  .content-header {
    .breadcrumbs {
      font-size: 20px;
      justify-content: center;
      margin-bottom: 40px;
    }
  }
  #contact_us {
    .cs-contact-form__errors {
      color: $color-pink-accent;
      margin-bottom: 20px;
    }
    .fieldset {
      @include breakpoint($medium-up) {
        float: $ldirection;
        width: 45%;
      }
      &.my_order {
        width: 90%;
        select {
          width: 98%;
          padding-top: 0;
        }
      }
      &.my_message {
        width: 90%;
        textarea {
          width: 98%;
        }
      }
      &.registered_member {
        width: auto;
        margin-top: 10px;
        div {
          display: inline-block;
          margin-#{$rdirection}: 20px;
          input {
            width: auto;
          }
        }
      }
      &.check_signup {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 20px;
        input {
          width: auto;
        }
      }
      &.submit {
        width: 35%;
        float: none;
      }
      input {
        width: 96%;
      }
    }
  }
}
