#samples {
  .samples-page {
    li {
      &.js-grid-item {
        float: none;
        width: 24%;
      }
    }
  }
}
