.checkout {
  .checkout-panel {
    &--new-account {
      .new-account-content {
        .form-item {
          margin-bottom: 20px;
        }
      }
    }
    &--offer-code {
      .offer-code-content {
        &__btn {
          width: 100%;
          &.form-item {
            @include breakpoint($landscape-up) {
              width: 35%;
            }
          }
        }
        &__input {
          @include breakpoint($medium-down) {
            float: $ldirection;
            margin-bottom: 10px;
            width: 100%;
          }
        }
      }
    }
    &__content {
      .input_password {
        width: 100%;
      }
      #bill_to_shipping_container {
        .radio input {
          margin-right: 5px;
        }
      }
    }
    &--promotions-container,
    &__content--promotions {
      .radio input {
        margin-right: 5px;
      }
    }
    &__content--terms {
      input {
        margin-right: 5px;
      }
    }
    .payment-option {
      img,
      label,
      .text-label {
        margin-#{$ldirection}: 10px;
      }
    }
    .messages.error {
      a:link,
      a:visited {
        color: $color-pink-accent;
      }
      a:hover {
        color: $color-pink-1;
      }
    }
    .address-form__title {
      .radio input {
        margin-left: 10px;
        margin-right: 3px;
      }
    }
  }
}
