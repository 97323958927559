.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  margin-bottom: 10px;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: underline;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: $color-dark-grey;
    border: 1px solid $color-light-gray;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 100%;
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-dark-grey transparent;
    }
  }
  .tooltiptext-over {
    bottom: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-dark-grey transparent transparent transparent;
    }
    .waitlist-legal-copy {
      line-height: 17px;
      font-size: 13px;
      text-align: $ldirection;
    }
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      padding: 10px;
      visibility: visible;
      opacity: 1;
    }
  }
  &.tooltip-right-margin {
    margin-#{$rdirection}: 15px;
  }
  &.tooltip-top-margin {
    margin-top: 15px;
  }
  &.tooltip-bottom-margin {
    margin-bottom: 15px;
  }
  &.text-right {
    text-align: $rdirection;
  }
  &.style-right {
    text-align: $rdirection;
    .tooltiptext-over {
      text-align: $rdirection;
      left: auto;
      right: 0;
      &::before {
        left: auto;
        right: 17%;
      }
    }
  }
  &.text-left {
    text-align: $ldirection;
  }
  .text-small {
    font-size: 10px;
  }
}

.new-account__container,
.promotions-container {
  .tooltip {
    &.text-left {
      margin-top: 15px;
    }
  }
}

#checkout_shipping {
  .tooltip {
    &.text-left {
      padding-left: 1rem;
    }
  }
}

#completed_checkout_registration {
  .tooltip {
    &.text-left {
      padding-left: 1rem;
    }
  }
  input.form-submit {
    margin-left: 1rem;
  }
}

.checkout-panel {
  #billing_address_form_container {
    .tooltip.text-left {
      padding-left: 0;
    }
  }
}

.site-header__navbar {
  .signin-block__footer {
    .tooltip--email_signup {
      text-align: center;
    }
    .signin-block__checkboxes {
      input {
        margin-#{$rdirection}: 5px;
      }
    }
  }
}

.tooltipster-base {
  .tooltipster-content {
    background-color: $color-white;
    padding: 15px;
    color: $color-black;
    border: 1px solid $color-pink-accent;
    transition: opacity 0.3s;
    p {
      text-align: center;
    }
  }
}

.section-chatprivacypolicy {
  .site-footer,
  .site-header,
  .footer,
  .site-header-wrapper {
    display: none !important;
  }
}

#power_review_container {
  .p-w-r {
    .pr-checkbox {
      span {
        float: $ldirection;
        width: 90%;
      }
      input {
        float: $ldirection !important;
        margin-top: 6px;
      }
      margin-bottom: 30px !important;
    }
  }
}
