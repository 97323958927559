#countryPickerLinkWrap,
#languagePickerLinkWrap,
#selectListSeparator {
  display: none;
}

#localeOverlay {
  background: $color-overlay-dark;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;
  position: fixed;
  #localeInterstitial {
    .inner-border {
      padding: 0.5em;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

#localeSwitchOverlay {
  background: $color-overlay-dark;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;
  position: fixed;
  .column {
    padding: 1em;
    float: left;
  }
}

#localeSwitchWarning {
  position: relative;
  width: 85%;
  margin: auto;
  padding: 2em;
  background: $color-white;
  font-size: 14px;
  line-height: 2em;
  text-align: center;
  .column {
    float: right;
    width: 100%;
  }
  .btn-secondary {
    background: $color-white;
    color: $color-black;
  }
}

#localeInterstitial {
  position: relative;
  top: 12%;
  width: 85%;
  max-width: 800px;
  margin: auto;
  padding: 2px;
  background: $color-white;
  line-height: 2em;
  text-align: center;
  .welcome-label {
    margin-bottom: 20px;
    font-family: $font--bauer-bodoni;
    font-size: 25px;
  }
  input[type='radio'] {
    opacity: 1;
    margin: 0;
    position: relative;
    left: inherit;
  }
  .column {
    width: 100%;
    padding: 1em;
    float: left;
  }
  .initial-hide {
    display: none;
    line-height: 21px;
  }
  ul {
    font-size: 0; /* Fix for country options having whitespace between them */
    width: 100%;
    a,
    a:hover {
      text-decoration: none;
      color: $color-black;
    }
    li {
      width: 29%;
      display: inline-block;
      float: none;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }
    &.country-list {
      padding: 0;
      list-style-type: none;
      &-offsite {
        padding: 0;
        list-style-type: none;
      }
    }
    &.language-list {
      padding: 0;
      list-style-type: none;
      li {
        width: 30% !important;
        padding: 0;
      }
    }
  }
  .country-link {
    display: inline-block;
    line-height: 2em;
    &.selected {
      font-weight: bold;
    }
  }
  .language-list {
    li {
      &.selected {
        font-weight: bold;
      }
    }
  }
  .label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }
  .inner-border {
    padding: 3em 6em;
  }
  .interstitial-image {
    display: none;
  }
}

#countryPickerWrap {
  .mobileCountryLink {
    font-family: $font--proxima-nova;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    min-width: 250px;
    max-width: 100%;
    padding: 15px 10px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-out;
    font-size: 12px;
    min-height: 2.7rem;
    cursor: pointer;
    color: $color-white;
    background: $color-black;
    input {
      display: none;
    }
    &:active,
    &:focus,
    &:hover {
      background: $color-black;
    }
  }
}
