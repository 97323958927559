#review {
  .checkout-panel {
    .cart-subtotal-row {
      text-align: $rdirection;
      padding: 20px 0;
      border-bottom: 1px solid $color-lighter-gray;
      font-weight: bold;
    }
  }
}
