#profile_preferences {
  .profile-info {
    &__item {
      margin-#{$rdirection}: 10px;
    }
  }
  .title_container {
    input {
      margin-#{$rdirection}: 5px;
    }
    span {
      margin-#{$rdirection}: 5px;
    }
  }
  .pc_email_promo_container {
    input[type='checkbox'] {
      margin-#{$rdirection}: 5px;
    }
  }
  .optional-info__fieldset {
    @include body-small;
    .age,
    .skin-type,
    .gender {
      label {
        &.label {
          margin: 5px 0 7px;
          float: $ldirection;
          width: 100%;
          font-weight: 700;
        }
      }
      input {
        margin-#{$rdirection}: 5px;
      }
    }
  }
  .privacy_policy_container {
    margin-top: 20px;
  }
}
