#password_request {
  .request-form {
    .text-left {
      text-align: $ldirection;
      margin-top: 20px;
    }
    &__item {
      width: 100%;
      input[type='text'] {
        width: 100%;
      }
    }
  }
}
