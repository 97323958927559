.site-header {
  &-wrapper {
    .site-header__utilities .flex-grid {
      @include breakpoint($medium-up) {
        flex-wrap: nowrap;
      }
    }
  }
  &__logo {
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
  .gnav-section {
    @include breakpoint($medium-up) {
      margin: 0 15px;
    }
  }
}

.content-block--staggered-tout {
  &.staggered-grid-tout {
    background: $color-overlay-70;
    @include breakpoint($medium-up) {
      background: none;
    }
  }
}

// TODO: clean up styles after launch of BEXCB-340
// Styles from Drupal EU node/17739

body .p-w-r .pr-rd-star-rating .pr-snippet-stars .pr-star-v4-100-filled::after,
body .p-w-r .pr-snippet .pr-snippet-stars .pr-star-v4-100-filled::after {
  clip: rect(0, 22px, 100px, 0);
  width: 22px;
}

body .p-w-r .pr-rd-star-rating .pr-snippet-stars .pr-star-v4::after,
.p-w-r .pr-rd-star-rating .pr-snippet-stars .pr-star-v4::before,
body .p-w-r .pr-snippet .pr-snippet-stars .pr-star-v4::after,
.p-w-r .pr-snippet .pr-snippet-stars .pr-star-v4::before {
  width: 22px;
}

body .p-w-r .pr-rd-star-rating .pr-snippet-stars .pr-star-v4,
body .p-w-r .pr-snippet .pr-snippet-stars .pr-star-v4 {
  /* stylelint-disable-next-line declaration-no-important */
  width: 22px !important;
}

body .product-full__offer__content {
  line-height: 1;
}

@media only screen and (max-width: 768px) {
  body .product-full__offer__content .mantle-custom-text {
    font-size: 14px;
  }
}

body .mpp-sort__container {
  position: static;
  transform: none;
  -webkit-transform: none;
}

@media only screen and (min-width: 768px) {
  body .mpp-sort__container {
    position: relative;
    transform: none;
    -webkit-transform: none;
    left: 0;
  }
}

body .mpp-sort__label {
  transform: none;
  -webkit-transform: none;
}

.flex--small-1[data-grid-row-gap='1%'] .flex__item {
  margin-top: 1%;
}

.flex--small-1[data-grid-row-gap='1%'] .flex__item:first-child {
  margin-top: 0;
}

.flex--small-2[data-grid-row-gap='2%'] .flex__item {
  margin-top: 2%;
}

.flex--small-2[data-grid-row-gap='2%'] .flex__item:first-child {
  margin-top: 0;
}

@media only screen and (min-width: 768px) {
  .flex--medium-1[data-grid-row-gap='1%'] .flex__item {
    margin-top: 1%;
  }
  .flex--medium-1[data-grid-row-gap='1%'] .flex__item:first-child {
    margin-top: 0;
  }
  .flex--medium-2[data-grid-row-gap='1%'] .flex__item {
    margin-top: 2%;
  }
  .flex--medium-2[data-grid-row-gap='1%'] .flex__item:first-child {
    margin-top: 0;
  }
}

.collection-page-formatter__content-region {
  position: relative;
}
/* stylelint-disable-next-line max-line-length */
.bundle-brief[data-sku-as-product='1'] .product-brief__shade-picker .product-shade-picker {
  display: none;
}

.content-block .content-block__content-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1024px) {
  body .site-header .menu__item--category--image {
    max-width: none;
  }
}

@media (min-width: 768px) {
  /* stylelint-disable-next-line max-line-length */
  .basic-carousel-formatter:not(.basic-carousel-formatter--pc_peeking) .basic-carousel:not(.slick-initialized)[data-slides-show='1'] > .basic-carousel__slide:not(:first-child) .lazyload {
    display: none;
  }
  /* stylelint-disable-next-line max-line-length */
  html:not(.window-loaded) .basic-carousel-formatter:not(.basic-carousel-formatter--pc_peeking) .basic-carousel[data-slides-show='1'] .slick-slide:not(.slick-active) .lazyload {
    display: none;
  }
}

@media (max-width: 768px) {
  /* stylelint-disable-next-line max-line-length */
  .basic-carousel-formatter:not(.basic-carousel-formatter--mob_peeking) .basic-carousel:not(.slick-initialized)[data-slides-show-mobile='1'] > .basic-carousel__slide:not(:first-child) .lazyload {
    display: none;
  }
  /* stylelint-disable-next-line max-line-length */
  html:not(.window-loaded) .basic-carousel-formatter:not(.basic-carousel-formatter--mob_peeking) .basic-carousel[data-slides-show-mobile='1'] .slick-slide:not(.slick-active) .lazyload {
    display: none;
  }
}

html:not(.window-loaded) .js-content-dim--menu:not(.active) {
  /* stylelint-disable-next-line declaration-no-important */
  background-image: none !important;
}
/* Let hero take up space till i figure out better solution */
@media (max-width: 767px) {
  /* stylelint-disable-next-line max-line-length */
  .homepage-formatter .homepage-hero-carousel .content-block--hero .content-block__picture .mantle-media-asset {
    height: calc((1110 / 780) * 100vw);
  }
}

html:not([lang='en-US']) .us-locale-shown {
  display: none;
}

html[lang='en-US'] .us-locale-hidden {
  display: none;
}

.product-full__smart-gift--pc {
  flex: 1;
}
/* stylelint-disable-next-line max-line-length */
.product-full__carousel__slides:not(.slick-initialized) .product-full__carousel__slide.initial-current-slide {
  margin: 0 12vw;
}

@media (min-width: 1024px) {
  /* stylelint-disable-next-line max-line-length */
  .product-full__carousel__slides:not(.slick-initialized) .product-full__carousel__slide.initial-current-slide {
    margin: 0;
  }
}

body .mantle-form__checkbox__description {
  display: block;
}

@media (min-width: 768px) {
  body .content-block--staggered-tout.staggered-grid-tout {
    background: none;
  }
}

@media (max-width: 767px) {
  body .content-block--staggered-tout.staggered-grid-tout {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .content-block--staggered-tout.staggered-grid-tout .staggered-grid-tout__inner {
    flex-direction: column;
  }
  .content-block--staggered-tout.staggered-grid-tout .staggered-grid-tout__media,
  .content-block--staggered-tout.staggered-grid-tout .staggered-grid-tout__content {
    width: 100%;
  }
  .content-block--staggered-tout.staggered-grid-tout .staggered-grid-tout__content {
    padding: 0.5em;
    order: 2;
    text-align: center;
  }
  .content-block--staggered-tout.staggered-grid-tout .staggered-grid-tout__content-pad {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 !important;
  }
}
/* Menu fix when too many items */
@media (min-width: 768px) {
  .site-header-wrapper .site-header__utilities .flex-grid {
    flex-wrap: nowrap;
  }
  .site-header__logo {
    width: auto;
  }
  .site-header .gnav-section {
    margin: 0 15px;
  }
}

@media (min-width: 768px) {
  .fall-shop__staggered-grid .staggered-grid-tout {
    background: none;
  }
}

@media (max-width: 767px) {
  .video--icon-only .videojs-video .videojs-video__player {
    opacity: 0;
  }
  .fall-shop__staggered-grid .staggered-grid-tout__inner {
    flex-direction: column;
  }
  .fall-shop__staggered-grid .staggered-grid-tout__media,
  .fall-shop__staggered-grid .staggered-grid-tout__content {
    width: 100%;
  }
  .fall-shop__staggered-grid .staggered-grid-tout__content {
    padding: 0.5em;
    order: 2;
  }
  .fall-shop__staggered-grid .staggered-grid-tout__content-pad {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 !important;
  }
}
// END / Styles from Drupal EU node/17739
