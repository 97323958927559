.profile-info {
  &__meter-status {
    font-weight: bold;
  }
  &__fieldset {
    .form-item {
      margin-bottom: 5px;
    }
  }
  &__item {
    .form-submit {
      margin-top: 10px;
    }
  }
  &__meter {
    height: 8px;
    border-radius: 4px;
    &--container {
      margin-top: 6px;
      background-color: $color-gray;
      margin-bottom: 6px;
    }
    &--progress {
      background-color: $color-gray;
      width: 0;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }
  }
  &__meter-wrapper {
    .profile-info {
      &__strength-list {
        display: inline-block;
        li {
          display: none;
          font-style: italic;
        }
      }
    }
    &.weak,
    &.no-score {
      .profile-info {
        &__meter {
          &--progress {
            width: 0;
          }
        }
        &__strength-list {
          .no-score {
            display: inline-block;
          }
        }
      }
    }
    &.bad {
      .profile-info {
        &__meter {
          &--progress {
            width: 25%;
            background-color: $color-red;
          }
        }
        &__strength-list {
          .bad {
            display: inline-block;
          }
        }
      }
    }
    &.average {
      .profile-info {
        &__meter {
          &--progress {
            width: 50%;
            background-color: $color-average;
          }
        }
        &__strength-list {
          .average {
            display: inline-block;
          }
        }
      }
    }
    &.good {
      .profile-info {
        &__meter {
          &--progress {
            width: 75%;
            background-color: $color-good;
          }
        }
        &__strength-list {
          .good {
            display: inline-block;
          }
        }
      }
    }
    &.strong {
      .profile-info {
        &__meter {
          &--progress {
            width: 100%;
            background-color: $color-green;
          }
        }
        &__strength-list {
          .strong {
            display: inline-block;
          }
        }
      }
    }
  }
}
