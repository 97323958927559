.shipping {
  .address-form {
    &__title {
      margin-bottom: 15px;
    }
    .field-container {
      margin-top: 15px;
    }
  }
  #delivery-options {
    .ship-method-home-group {
      margin-bottom: 15px;
    }
  }
  .error_messages {
    li {
      margin-bottom: 5px;
      margin-#{$ldirection}: 15px;
      a:link,
      a:visited {
        color: $color-pink-accent;
      }
      a:hover {
        color: $color-pink-1;
      }
    }
  }
}

.checkout-index {
  .address-overlay {
    .form-item {
      .label {
        &::before {
          padding-bottom: 10px;
        }
      }
    }
  }
}
