.profile-pic-upload {
  &__gdpr-link {
    color: $color-pink-accent;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    &:hover > .profile-pic-upload__gdpr-overlay {
      display: block;
    }
  }
  &__gdpr-overlay {
    display: none;
    position: absolute;
    color: $color-black;
    border: 1px solid $color-pink-accent;
    text-decoration: none;
    padding: 16px;
    background-color: $color-white;
    bottom: 20px;
    width: 300px;
    p {
      margin: 0;
    }
  }
}
